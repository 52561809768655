import React from 'react';
import './ShutTheBox.css';

class ShutTheBox extends React.Component {
    state = {
        numbers: [], // true = 'up' and false = 'down'
    }
    componentDidMount() {
        let numbers = [];
        for (let i = 0; i < this.props.size; i++) {
            numbers.push(true);
        }
        this.setState({numbers})
    }
    render() {
        return (
            <div className="ShutTheBoxContainer">
                <div className="ShutTheBoxFlex">
                    {this.state.numbers.map((x, ix) => {
                        let css = "ShutTheBoxBrick";
                        if (x === false) {
                            css += " ShutTheBoxBrickDown";
                        }
                        return (
                            <div className={css} onClick={() => this.toggle_brick(ix)}>
                                {ix + 1}
                            </div>
                        );
                    })}
                </div>
                <div>
                    <input className="ShutTheBoxRestart" type="button" value="Börja om" onClick={() => this.reset()} />
                </div>
            </div>
            );
    }

    toggle_brick(ix) {
        let numbers = this.state.numbers;
        if (0 <= ix && ix < numbers.length) {
            numbers[ix] = !numbers[ix];
        }
        this.setState({numbers});
    }

    reset() {
        let numbers = this.state.numbers;
        for (let i = 0; i < numbers.length; i++) {
            numbers[i] = true;
        }
        this.setState({numbers});
    }
}

export default ShutTheBox;
