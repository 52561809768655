import React from 'react';
import './15-puzzle.css';

const numbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,0];

/**
 * All numbers are index + 1.
 */
const movetable = {
    1: [2, 5],
    2: [1, 3, 6],
    3: [2, 4, 7],
    4: [3, 8],
    5: [1, 6, 9],
    6: [2, 5, 7, 10],
    7: [3, 6, 8, 11],
    8: [4, 7, 12],
    9: [5, 10, 13],
    10: [6, 9, 11, 14],
    11: [7, 10, 12, 15],
    12: [8, 11, 16],
    13: [9, 14],
    14: [10, 13, 15],
    15: [11, 14, 16],
    16: [12, 15],
}

class FifteenPuzzle extends React.Component {
    state = {
        game: [...numbers],
        wasScrambled: false,
        numberMoves: 0,
    }
    render() {
        return (
            <div className="Flex-15">
                <div className="Flex-Buttons-15">
                    <div className="Button-15-Easy" onClick={() => this.scramble(5)}>Lätt</div>
                    <div className="Button-15-Meduim" onClick={() => this.scramble(10)}>Medel</div>
                    <div className="Button-15-Hard" onClick={() => this.scramble(500)}>Svår</div>
                </div>
                <div className="Moves-15">
                Antal drag: {this.state.numberMoves}
                </div>
                <div className="Grid-15">
                    <RenderWinning state={this.state} onClick={() => {this.setState({wasScrambled: false})}}/>
                    {numbers.map( (n) => {
                        const nIx = this.state.game.findIndex((x) => {return x === n});
                        let className = n !== 0 ? "Button-15" : "Empty-15";
                        className += ` Row-15-${Math.floor(nIx/4)}`;
                        className += ` Column-15-${Math.floor(nIx%4)}`;
                        const onClick = n !== 0 ? () => {this.updateGame(nIx)} : () => {};
                        return (
                            <div className={className} onClick={onClick}>{n}</div>
                        )
                    })}
                </div>
            </div>
        );
    }
    updateGame(ix) {
        const nullIx = this.state.game.findIndex((x) => {return x === 0});
        if(movetable[ix+1].find((x) => {return x === nullIx+1}) !== undefined) {
            const newGame = this.swap(ix, nullIx);
            this.setState({
                game: newGame,
                numberMoves: this.state.numberMoves+1,
                });
        }
    }
    swap(ixA, ixB) {
        let newGame = [...this.state.game];
        newGame[ixA] = this.state.game[ixB];
        newGame[ixB] = this.state.game[ixA];
        return newGame;
    }
    /**
     * Make n random moves.
     */
    scramble(n) {
        if(n<0) {
            return;
        }
        const nullIx = this.state.game.findIndex((x) => {return x === 0});
        const possibleMoves = movetable[nullIx+1];
        const moveIx = Math.floor(Math.random() * possibleMoves.length);
        const move = possibleMoves[moveIx]; //Index+1
        const valueIx = move-1;
        const newGame = this.swap(nullIx, valueIx);
        this.setState({
            game: newGame,
            wasScrambled: true,
            numberMoves: 0,
        });
        setTimeout(() => this.scramble(n-1), 500/n);
    }
}

const RenderWinning = (props) => {
    const newGame = props.state.game;
    let cssClass = "Winning-15"
    if(props.state.numberMoves > 0 &&
       props.state.wasScrambled &&
       newGame.length === numbers.length &&
       newGame.every((value, index) => value === numbers[index]) ) {
        /* Do nothing */
    } else {
        cssClass += " Hidden-15";
    }
    return <div className={cssClass} onClick={props.onClick}>DU VANN!</div>
}

export default FifteenPuzzle;
