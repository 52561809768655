import React from 'react';

class SimpleAdd extends React.Component {
    state = {
        equation: {}, // a, b, answer
        correct: false,
        buttons: [], // {id, indication}
    }
    componentDidMount() {
        this.nextEquation();
    }
    render() {
        return (
            <div>
            <Next render={this.state.correct} next={this.nextEquation} />
            <p>
            {this.state.equation["a"]}
            {this.props.sign}
            {this.state.equation["b"]}
            =
            </p>
            {this.state.buttons.map( (b) => {
                return (<Button b={b} onClick={this.buttonPress}/>);
            })}
            </div>
            );
    }
    buttonPress = (id) => {
        let b = this.state.buttons;
        if (id === this.state.equation["answer"]) {
            this.setState({correct: true});
            b[id] = {id: id, indication: 'correct'};
        } else {
            b[id] = {id: id, indication: 'wrong'};
        }
        this.setState({buttons: b});
    }
    nextEquation = () => {
        let answer = 0;
        let a = 0;
        let b = 0;
        if (this.props.sign === '+') {
            answer = nextNum(this.props.upperLimit);
            a = nextNum(answer);
            b = answer - a;
        } else if (this.props.sign === '-') {
            a = nextNum(this.props.upperLimit);
            b = nextNum(a);
            answer = a - b;
        }
        const neq = {"a":a,
                "b":b,
                "answer": answer};
        let bu = []
        for(let i = 0; i < this.props.upperLimit; i++) {
            bu = [...bu,{id: i, indication: "unknown"}];
        }
        this.setState({equation: neq,
                       buttons: bu,
                       correct: false,
                       });
    }
}

const Button = (props) => {
    const {id, indication} = props.b;
    let color = 'lightgrey';
    if (indication === "wrong") {
        color = 'red';
    } else if (indication === "correct") {
        color = 'green';
    }
    return (<input type="button"
                   value={id}
                   style={{backgroundColor: color,
                           width: '3em',
                           height: '2em',
                           margin: '1em',
                         }}
                   onClick={props.onClick.bind(this, id)}/>);
}

const Next = (props) => {
    console.log(props);
    if (props.render === true) {
        return (
        <div>
        <input type="button" value=">>" onClick={props.next.bind(this)}/>
        </div>
        );
    } else {
        return (<div></div>);
    }
}

const nextNum = (m) => {
    return Math.floor(Math.random()*m);
}

export default SimpleAdd;
