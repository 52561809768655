import React, { useState } from 'react';

export const ColorButtons = () => {
    return (
    <div style={{
        display: 'flex',
        flexFlow: 'row wrap',
        }}>
    <ColorButton />
    <ColorButton />
    <ColorButton />
    <ColorButton />
    <ColorButton />
    <ColorButton />
    <ColorButton />
    <ColorButton />
    <ColorButton />
    <ColorButton />
    </div>
    );
}

const ColorButton = () => {
    const [color, setColor] = useState('rgb(242,15,236)');
    const [bText, setBText] = useState(':-)');
    return (
        <div>
        <input type="button" value={bText}
            style={{backgroundColor: color,
                    fontSize: '20px',
                    height: '200px',
                    width: '200px',
                    borderRadius: '50%',
                    transform: 'rotate(90deg)',
                    border: 'none',
                    margin: '1em',
                    }} onClick={() => {setColor(newColor()); setBText(newText())}} />
        <p>{color}</p>
        </div>
    );
}

const newText = () => {
    const r = num255();
    if (r < 10) {
        return "*<|:‑)";
    } else if (r < 20) {
        return ":-D";
    } else if (r < 45) {
        return ":-P";
    } else if (r < 100) {
        return ":-(";
    } else {
        return ":-)";
    }
}

const newColor = () => {
    const r = num255();
    const g = num255();
    const b = num255();
    const str = "rgb(" + r + "," + g + "," + b + ")";
    console.log(str);
    return str;
}

const num255 = () => {
    return Math.floor(Math.random()*255);
}
