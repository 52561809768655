import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import './App.css';
import {ColorButtons} from './components/ColorButtons';
import SimpleAdd from './components/SimpleAdd';
import FifteenPuzzle from './components/15-puzzle';
import fifteenPuzzleImg from './images/15-puzzle.png';
import ShutTheBox from './components/ShutTheBox';

const prefix = ""; // Prefix if published with a special url prefix.

function App() {
  return (
    <Router>
    <div className="App">
        <Home />
      <header className="App-header">
        <Route exact path={prefix + "/"} render={() => (<FirstPage />) } />
        <Route path={prefix + "/colorbuttons"} render={() => (<ColorButtons />) } />
        <Route path={prefix + "/simpleadd"} render={() => (<SimpleAdd sign='+' upperLimit={10} />) } />
        <Route path={prefix + "/simpleadd20"} render={() => (<SimpleAdd sign='+' upperLimit={21} />) } />
        <Route path={prefix + "/simpleadd50"} render={() => (<SimpleAdd sign='+' upperLimit={51} />) } />
        <Route path={prefix + "/simplesub"} render={() => (<SimpleAdd sign='-' upperLimit={6} />) } />
        <Route path={prefix + "/fifteenpuzzle"} render={() => (<FifteenPuzzle />) } />
        <Route path={prefix + "/shutthebox"} render={() => (<ShutTheBox size={9}/>) } />
      </header>
    </div>
    </Router>
  );
}

const FirstPage = () => {
    return (
        <ul>
            <li> <Link to={prefix + "/colorbuttons"}><input type="button" value=":-)"
            style={{backgroundColor: 'rgb(242,15,236)',
                    fontSize: '10px',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50%',
                    transform: 'rotate(90deg)',
                    border: 'none',
                    margin: '1em',
                    }} />
                </Link> </li>
            <li> <Link to={prefix + "/simpleadd"}>3+2=?</Link> </li>
            <li> <Link to={prefix + "/simpleadd20"}>13+5=?</Link> </li>
            <li> <Link to={prefix + "/simpleadd50"}>23+15=?</Link> </li>
            <li> <Link to={prefix + "/simplesub"}>3-2=?</Link> </li>
            <li> <Link to={prefix + "/fifteenpuzzle"}><img width="50px" height="50px" src={fifteenPuzzleImg} alt="15-spel" title="15-spel"/></Link> </li>
            <li> <Link to={prefix + "/shutthebox"}>Stäng lådan</Link> </li>
        </ul>);
}

const Home = () => {
    return <Link to={prefix + "/"}>Hem</Link>
}

export default App;
